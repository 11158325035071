.navbar {
  justify-content: center;
}

.navbar-nav {
  flex-direction: row;
}

.nav-item {
  flex-grow: 1;
  text-align: center;
}

.navbar-brand {
  flex-grow: 1;
}

nav img {
  height: 30px;
  margin-top: -5px;
}

.container {
  text-align: center;
}

.slideshow-container {
  max-width: 1000px;
  height: 55vh;
  position: relative;
  margin: auto;
}

.slideshow-large-container {
  max-width: 1000px;
  height: 80vh;
  position: relative;
  margin: auto;
}

.slide {
  display: none;
  height: 100%;
  position: relative;
}

.slide.active {
  display: block;
}

.slide img {
  max-height: 100%;
  width: auto;
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dot {
  height: 15px;
  width: 15px;
  margin: 5px 2px 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.dot.active {
  background-color: #717171;
}

.badge {
  margin: 20px 10px;
}

.badge img {
  height: 30px;
}

.team-intro {
  text-align: center;
  width: 70vh; 
  margin: 0 auto; /* Apply automatic margins to the left and right */
}

.menu img {
  height: 80vh;
}

.location img {
  height: 80vh;
}

.business-info {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 10px;
}

.business-info img {
  width: 150px;
  margin-left: 30px;
}

@media (max-width: 768px) {
  .menu img {
    height: 35vh;
  }
  .location img {
    height: 75vh;
  }
  .slideshow-container {
    height: 35vh;
  }
  .slideshow-large-container {
    height: 50vh;
  }
  .team-intro {
    width: 40vh;
  }
}